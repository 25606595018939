<template>
    <div class="notice-container">
        <a @click="toggleVisibility" class="">
            About
        </a>
        <div :aria-hidden="hideInfo" class="notice">
            <p v-html="infoContent"></p>
        </div>  
    </div>
</template>
<script>
import { ref } from 'vue';
import { infoModalText } from '../infoModalText.js'
export default {
    name: 'AboutInfo',
    setup(props) {
        const hideInfo = ref(true);
        const infoContent = infoModalText.text.replace(/\n\n/g, '<br>');

        const toggleVisibility = () => {
            hideInfo.value = !hideInfo.value;
            console.log(hideInfo.value);
        };
        return {
            toggleVisibility,
            hideInfo,
            infoContent
        };
    }
};
</script>
<style>

.notice-container > a {
    text-decoration: underline;
    margin-bottom: 10px;
    display: block;
    cursor: pointer;
}
.notice {
    background-color: #f1f1f1;
    padding: 10px;
    border-radius: 5px;
    margin-top: 10px;
    margin-bottom: 10px;
    &[aria-hidden="true"] {
        display: none;
    }
}
    
</style>