<template>
  <div class="category-select-container">
  <div class="category-select-container-content">
    <button 
      v-for="category in categories" 
      :key="category.key" 
      :title="category.description"
      @click="$emit('select-category', category.key);"
      :class="[{ 'selected': selectedCategory === category.key }, button]"
    >
      {{ category.label }}
    </button>
  </div>
  </div>
</template>

<script>
export default {
  name: 'CategorySelector',
  props: ['categories', 'selectedCategory'],
  emits: ['select-category'],
}
</script>

<style scoped>
/* ... (category button styles) */
</style>