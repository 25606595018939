<template>
  <div class="tooltip-container" :style="{ top: tooltipPosition.top + 'px', left: tooltipPosition.left + 'px' }">
    <slot></slot>
    <div class="tooltip" :class="{ 'visible': showTooltip }">
      {{ content }}
    </div>
  </div>
</template>

<script>
import { useSongSelectionStore } from '../useSongSelection.js';
import { toRefs } from 'vue';
const content = ("Tiktok does not allow to enter search. So song and artist is copied to your clipboard. Open TikTok and paste");


export default {
  name: 'ToolTip',
  setup() {
    const store = useSongSelectionStore();
    const { showTooltip, tooltipPosition } = toRefs(store);
    return {
      showTooltip,
      tooltipPosition,
      content
    }
  }
}
</script>

<style scoped>
.tooltip-container {
  position: fixed;
  /* display: inline-block; */
  z-index: 1000;
  left: -1000px;
  top: 0;
  width: 240px;
  pointer-events: none;
}

.tooltip {
  padding: 5px 16px;
  /* bottom: 100%;
    left: 50%; */
  background-color: #333;
  color: #fff;
  border-radius: 4px;
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip.visible {
  opacity: 1;
}
</style>