<template>
    <div 
    :class='[
                "sidebar-container sidebar-container-songs",
                {
                    "is-connected": songSelectionStore.selectedSpotifyPlaylistName
                }
            ]'
    >
        <h2>Selected Songs</h2>
        <ul class="sidebar-songs">
            <li v-for="song in songSelectionStore.selectedSongs" :key="song?.spotify_track_id" class="sidebar-song">
                <template v-if="song && song.spotify_track_id">
                    <button @click="songSelectionStore.toggleSongSelection(song)" title="delete selection">
                        <svg xmlns="http://www.w3.org/2000/svg" width="34" height="30" viewBox="0 0 24 24"
                            style="fill: rgba(255,255,255, 1);">
                            <g transform="translate(0, 2)">
                                <path
                                    d="m16.192 6.344-4.243 4.242-4.242-4.242-1.414 1.414L10.535 12l-4.242 4.242 1.414 1.414 4.242-4.242 4.243 4.242 1.414-1.414L13.364 12l4.242-4.242z">
                                </path>
                            </g>
                        </svg>
                    </button>
                    <div class="sidebar-song-info">
                        <span class="sidebar-song-info-title">{{ song.song_name }}</span>
                        <span class="sidebar-song-info-artist"> {{ song.artist_name }} <i>({{ `${song.rank} in ${song.category}` }})</i> </span>
                    </div>
                    <div v-if="songSelectionStore.selectedSpotifyPlaylistName && !songSelectionStore.wasSongAddedToPlaylist(song.spotify_track_id)"
                        class="button-add">
                        <button @click="songSelectionStore.addOrDeleteSongToPlaylist(song.spotify_track_id, 'add')"
                            title="add to playlist">
                            {{ `Add to ${songSelectionStore.selectedSpotifyPlaylistName}` }}
                        </button>
                    </div>
                    <div v-else-if="songSelectionStore.selectedSpotifyPlaylistName && songSelectionStore.wasSongAddedToPlaylist(song.spotify_track_id)"
                        class="button-remove">
                        <button @click="songSelectionStore.addOrDeleteSongToPlaylist(song.spotify_track_id, 'delete')"
                            title="remove from playlist">
                            {{ `Remove from ${songSelectionStore.selectedSpotifyPlaylistName}` }}
                        </button>
                    </div>
                </template>
            </li>
            <li v-if="songSelectionStore.selectedSongs.length===0">
                No Songs selected
            </li>
        </ul>
        <div class="sidebar-notice" v-if="!songSelectionStore.isSpotifyConnected">
            <span>Connect to spotify to add songs to playlists</span>
        </div>
    </div>
</template>

<script>
import { useSongSelectionStore } from '../useSongSelection'
import { onMounted, watch } from 'vue';

export default {
    name: 'SidebarSongs',
    props: {},
    setup() {
        const songSelectionStore = useSongSelectionStore()

        // watch(() => songSelectionStore.selectedSpotifyPlaylist, (newValue) => {
        //     console.log('Selected playlist changed:', newValue);
        // }, { deep: true });

        // watch(() => songSelectionStore.selectedSongs, (newSelectedSongs) => {
        //     console.log('Selected songs changed:', newSelectedSongs);
        // }, { deep: true });

        // onMounted(() => {
        //     console.log('selectedSongs', songSelectionStore.selectedSongs);
        // })

        return {
            songSelectionStore
        }
    }
}
</script>

<style scoped>
/* all text is blueish here make it white */
</style>
