<template>
  <div class="sidebar-container sidebar-container-spotify-player">
    <h2>Spotify Playlist</h2>
    <label>
      <input type="checkbox" v-model="userAgreed">
      <span>Agree to load the Spotify player</span>
    </label>
    <div v-if="userAgreed" class="playlists-select-container">
      <span>Default Top-100 Playlist:</span>
      <select v-if="categories.length" @change="onSelectPlaylist">
        <option value="">Select from Default Playlist</option>
        <option v-for="category in categories" :key="category.id" :value="category.spotify_cat_id">
          {{ category.label }}
        </option>
      </select>
    </div>
    <!-- <div v-if="userAgreed" class="inline-select"> -->
      <div v-if="userAgreed && currentUsersPlaylists.length" class="playlists-select-container">
        <span>Your Playlists: </span>
        <select v-if="currentUsersPlaylists.length" @change="onSelectPlaylist">
          <option value="">Select Playlist</option>
          <option v-for="playlist in currentUsersPlaylists" 
          :key="playlist[0]" 
          :value="playlist[0]">
            {{ playlist[1] }}
          </option>
        </select>
    </div>

    <iframe id="the_spotify_player"
    v-if="userAgreed && thePlaylist" 
    :src="`https://open.spotify.com/embed/playlist/${thePlaylist}`" width="100%" 
      frameborder="0" allowtransparency="true" allow="encrypted-media"></iframe>
  </div>
</template>

<script>
import { ref, onMounted, watch, toRefs } from 'vue';
import { useSongSelectionStore } from '../useSongSelection'

export default {
  name: 'SpotifyPlayer',
  props: {
    // playlistId: {
    //   type: String,
    //   required: true
    // }
  },
  setup(props) {
    const userAgreed = ref(false);
    const thePlaylist = ref('')
    const store = useSongSelectionStore();
    const { categories, currentUsersPlaylists } = toRefs(store);

    const onSelectPlaylist = (event) => {
      // console.log('Selected playlist changed:', event.target.value);
      thePlaylist.value = event.target.value;
    }

    const loadUserChoice = () => {
      const storedChoice = localStorage.getItem('spotifyPlayerAgreed');
      userAgreed.value = storedChoice === 'true';
    };

    const saveUserChoice = () => {
      localStorage.setItem('spotifyPlayerAgreed', userAgreed.value);
    };

    onMounted(() => {
      // console.log('SpotifyPlayer props', props);
      loadUserChoice();
    });

    watch(userAgreed, () => {
      // console.log("userAgreed", categories);
      saveUserChoice();
    });

    return {
      props,
      userAgreed,
      categories,
      onSelectPlaylist,
      thePlaylist,
      currentUsersPlaylists
    }
  }
}
</script>

<style scoped>
.spotify-player label input {
  width: 40px;
  margin-right: 0.5em;
  display: inline;
}
</style>
