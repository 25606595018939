<template>
  <SongListNew />
</template>

<script>
import SongListNew from './components/01_Main.vue'

export default {
  name: 'App',
  components: {
    SongListNew
  }
}
</script>

<style>
</style>
