<template>
  <li :class="[{ 'selected': isSelected }, 'song-item']" @click="toggleExpanded">
    <div class="row-1 song-info">
      <span class="rank">{{ song.rank }}</span>
      <div class="song-artist">
        <span class="song">{{ song.song_name }}</span>
        <span class="artist">{{ song.artist_name }}</span>
      </div>
      <span class="year">{{ song.release_date_year }}</span>
    </div>
    <div class="row-2 song-buttons">
      <button @click="toggleSelection($event, song.spotify_track_id)" :class="[
        { 'btn-secondary': !isSelected },
        'btn select-btn']">
        {{ isSelected ? 'Deselect' : 'Select' }}
      </button>
      <!-- <button @click="toggleExpanded" class="btn btn-secondary btn-more">
        More
      </button> -->
    </div>
    <div class="row-3 song-details">
      <button v-if="song.genius_url" class="btn link">
        <a :href="song.genius_url" target="_blank">
          Genius
        </a>
      </button>
      <button class="btn" @click="openTikTokSearch($event, song)">TikTok</button>
      <button class="btn" @click="openYouTubeSearch(song)">YouTube</button>
      <button class="btn" @click="openInSpotify(song)">Spotiy</button>
      <button class="btn" @click="openLyrics" v-if="song.genius_lyrics">
        Lyrics</button>
    </div>
  </li>
</template>

<script>

import { useSongSelectionStore } from '../useSongSelection'
import { ref } from 'vue'

export default {
  name: 'SongItem',
  props: {
    song: {
      type: Object,
      required: true
    },
    isSelected: {
      type: Boolean,
      default: false
    }
  },
  emits: ['toggle-selection', 'open-lyrics'],
  methods: {
    toggleSelection(event, spotify_track_id) {
      this.$emit('toggle-selection');
      // stop event from bubbling up to parent
      event.stopPropagation();
    },
    openLyrics() {
      // scroll window to #lyrics
      // window.location.href = '#lyrics';
      this.$emit('open-lyrics', this.song)
      // lets calculate the top of the lyrics modal
      const lyrics = document.getElementById('lyrics');
      const top = lyrics.offsetTop;//getBoundingClientRect().top;
      setTimeout(() => {
        window.scrollTo({ top: top - 30, behavior: 'smooth' });
      }, 100);
    },
    toggleExpanded(ev) {
      ev.target.closest('.song-item').classList.toggle('details-expanded');
    }
  },
  setup() {
    const { setShowTooltip, setTooltipPosition } = useSongSelectionStore()
    // const openGeniusPage = (song) => {
    //   window.open(song.genius_url, '_blank');
    // };

    function copyToClipboard(text) {
      try{

        navigator.clipboard.writeText(text).then(() => {
          console.log(`Text ${text} copied to clipboard`);
        })
      } catch(err) {
        console.error('Failed to copy text: ', err);
      }
    }
    function isMobileDevice() {
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    }

    const openTikTokSearch = (event, song) => {
      const searchQuery = `${song.artist_name} ${song.song_name}`;
      event.stopPropagation(); // do not toggle item expanded
      if(isMobileDevice()) {
        // Tiktok does not allow to enter search term on mobile devices
        // so we shpw a tooltip
        copyToClipboard(searchQuery);
        // get position of event and set tooltip position
        // add window.scrollY to top
        setTooltipPosition({ top:  event.clientY - 140, left: event.clientX - 10 });
        setShowTooltip(true);
        setTimeout(() => {
          setShowTooltip(false);
        }, 2000);
        return;
      }

      const encodedQuery = encodeURIComponent(searchQuery);
      const url = `https://www.tiktok.com/search?q=${encodedQuery}`;
      // add noopener noreferrer to link
      window.open(url, '_blank', 'noopener,noreferrer');
      // window.open(url, '_blank');
    };
    const openYouTubeSearch = (song) => {
      const searchQuery = `${song.artist_name} ${song.song_name}`;
      const encodedQuery = encodeURIComponent(searchQuery);
      const url = `https://www.youtube.com/results?search_query=${encodedQuery}`;
      window.open(url, '_blank');
    };
    const openInSpotify = (song) => {
      const url = `https://open.spotify.com/track/${song.spotify_track_id}`;
      window.open(url, '_blank');
    }
    return {
      isExpanded: false,
      openTikTokSearch,
      openYouTubeSearch,
      openInSpotify
      // openGeniusPage
    }
  }
}
</script>

<style scoped>
/* ... (song item styles) */
</style>
