<template>
  <div class="search-container">
    <input 
      :value="modelValue"
      @input="$emit('update:modelValue', $event.target.value)"
      class="search-input" 
      placeholder="Search lyrics..." 
    />
    <!-- <button v-if="modelValue" @click="$emit('reset')" class="reset-search">×</button> -->
  </div>
</template>

<script>
export default {
  name: 'SearchBar',
  props: ['modelValue'],
  emits: ['update:modelValue', 'reset']
}
</script>

<style scoped>
/* ... (search bar styles) */
</style>