import { defineStore } from 'pinia';
// import { toRaw } from 'vue';

const STORAGE_KEY = 'top100_selectedSongs'
const CATEGORY_KEY = 'top100_selectedCategory'

export const useSongSelectionStore = defineStore('songSelection', {
  state: () => ({
    selectedSongs: JSON.parse(localStorage.getItem(STORAGE_KEY) || '[]'),
    selectedCategory: localStorage.getItem(CATEGORY_KEY) || '',
    top_100_songs_added_to_playlist: JSON.parse(localStorage.getItem('top_100_songs_added_to_playlist') || '{}'),
    categories: [],
    isSpotifyConnected: false,
    selectedSpotifyPlaylist: '',
    selectedSpotifyPlaylistName: '',
    currentUsersPlaylists: [], // array [spotify_playlist_id, Name/Label]
    showTooltip: false,
    tooltipPosition: {
      top: 300,
      left: 100
    }
  }),

  /* 
          lets keep track of the song in the playlist
          let use a map to store category_id as key and an array of songs as value
          top_100_songs_added_to_playlist: {
            category_id: [spotify_track_id, spotify_track_id,...]
          }
          */


  actions: {
    setShowTooltip(bolValue) {
      this.showTooltip = bolValue
    },
    setTooltipPosition(position) {
      this.tooltipPosition = position
    },
    saveToLocalStorage() {
      localStorage.setItem(STORAGE_KEY, JSON.stringify(this.selectedSongs))
      localStorage.setItem(CATEGORY_KEY, this.selectedCategory)
    },

    setCurrentUsersPlaylists(aListOfCategoryIDs) {
      // console.log('set current users playlists --STORE--', aListOfCategoryIDs)
      this.currentUsersPlaylists = aListOfCategoryIDs
    },

    setIsSpotifyConnected(isSpotifyConnected) {
      this.isSpotifyConnected = isSpotifyConnected
    },

    setSelectedSpotifyPlaylist(playlist_id) {
      this.selectedSpotifyPlaylist = playlist_id
    },
    setSelectedSpotifyPlaylistName(playlist_name) {
      this.selectedSpotifyPlaylistName = playlist_name
    },
    // : 'add' | 'delete'
    async addOrDeleteSongToPlaylist(spotifySongId, method) {
      if(method!== 'add' && method!== 'delete') {
        console.error('Invalid method. Must be add or delete')
        return;
      }
      const METHOD = method === 'add'? 'POST' : 'DELETE';
      const endpoint = `https://api.spotify.com/v1/playlists/${this.selectedSpotifyPlaylist}/tracks`;
      const storedToken = localStorage.getItem('spotify_access_token');
      // console.log("Selected PLAYID", this.selectedSpotifyPlaylist)

      let body = {
        uris: [`spotify:track:${spotifySongId}`]
      }
      if (method === 'delete') {
        body = {
          tracks: [
            {
              uri: `spotify:track:${spotifySongId}`
            }
          ]
        }
      }

      if (!storedToken) {
        console.error('You seem not to be logged in to Spotify');
        return;
      }
      try {
        const response = await fetch(endpoint, {
          method: METHOD,
          headers: {
            'Authorization': `Bearer ${storedToken}`,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(body)
        });

        if (response.ok) {
          const data = await response.json();
          // console.log('Song ',method,' to playlist successfully', data);
          if (method === 'add') {
            this.addToAddedSongs(spotifySongId);
          } else {
            this.deleteFromAddedSongs(spotifySongId);
          }
        } else {
          const data = await response.json();
          console.error('Failed to' , method, ' song to playlist', data);
        }
      } catch (error) {
        console.error('Error adding song to playlist:', error);
      }
    },
   
    /* 
        lets keep track of the song in the playlist
        let use a map to store category_id as key and an array of songs as value
        */
    addToAddedSongs(spotifySongId) {
      if (!this.top_100_songs_added_to_playlist[this.selectedSpotifyPlaylist]) {
        this.top_100_songs_added_to_playlist[this.selectedSpotifyPlaylist] = [];
      }
      this.top_100_songs_added_to_playlist[this.selectedSpotifyPlaylist].push(spotifySongId);
      localStorage.setItem('top_100_songs_added_to_playlist', JSON.stringify(this.top_100_songs_added_to_playlist));
    },
    deleteFromAddedSongs(spotifySongId) {
      if (!this.top_100_songs_added_to_playlist[this.selectedSpotifyPlaylist]) {
        return;
      }
      const index = this.top_100_songs_added_to_playlist[this.selectedSpotifyPlaylist].findIndex(s => s === spotifySongId);
      if (index > -1) {
        this.top_100_songs_added_to_playlist[this.selectedSpotifyPlaylist].splice(index, 1);
      }
      localStorage.setItem('top_100_songs_added_to_playlist', JSON.stringify(this.top_100_songs_added_to_playlist));
    },

    setSelectedCategory(category) {
      this.selectedCategory = category
      this.saveToLocalStorage()
    },

    setCategories(categories) {
      this.categories = categories
    },

    toggleSongSelection(song) {
      // console.log('category', JSON.stringify(this.categories))
      const index = this.selectedSongs.findIndex(s => s.spotify_track_id === song.spotify_track_id)
      if (index > -1) {
        this.selectedSongs.splice(index, 1)
      } else {
        // add category label to song
        let catLabel = ''
        this.categories.forEach(c => {
          if (this.selectedCategory === c.key) {
            catLabel = c.label
          }
        })
        this.selectedSongs.push({
          spotify_track_id: song.spotify_track_id,
          artist_name: song.artist_name,
          song_name: song.song_name,
          category: catLabel,
          rank:song.rank
        })
      }
      this.saveToLocalStorage()
    },

    // addMultipleSongs(songs) {
    //   songs.forEach(song => {
    //     if (!this.selectedSongs.some(s => s.spotify_track_id === song.spotify_track_id)) {
    //       this.selectedSongs.push({
    //         spotify_track_id: song.spotify_track_id,
    //         artist_name: song.artist_name,
    //         song_name: song.song_name,
    //         category: song.category
    //       })
    //     }
    //   })
    //   this.saveToLocalStorage()
    // },

    clearSelection() {
      this.selectedSongs = []
      this.saveToLocalStorage()
    }
  },

  getters: {
    isSelected: (state) => (spotify_track_id) => {
      return state.selectedSongs.some(s => s.spotify_track_id === spotify_track_id)
    },
    wasSongAddedToPlaylist: (state) => (spotify_track_id) => {
      // console.log("TOP 100 SONGS", state.selectedSpotifyPlaylist, '<-plid', spotify_track_id)
      if (!state.top_100_songs_added_to_playlist[state.selectedSpotifyPlaylist]) {
        return false
      }
      return state.top_100_songs_added_to_playlist[state.selectedSpotifyPlaylist].includes(spotify_track_id)
    }
    // selectedCategory: (state) => {
    //   return state.selectedCategory
    // },
  }
})
