<template>
  <div id="top" :class="{ 'scrolled': isScrolled }">
    <header id="header" class="top-container">
      <AboutInfo />
      <CategorySelector :categories="categories" :selectedCategory="songSelectionStore.selectedCategory"
        @select-category="fetchCategory" />
      <h2 v-if="currentCatInfo" class="title">{{ currentCatInfo.description ?? 'Select Category' }}</h2>
      <SearchBar v-model="searchKeyword" @reset="resetSearch" />
    </header>
    <main id="main" class="song-list-container">
      <div v-if="loading" class="loader">Loading...</div>
      <transition-group name="fade" tag="ul" class="song-list" v-else>
        <SongItem v-for="song in filteredSongs" :key="song.spotify_track_id" :song="song"
          :isSelected="songSelectionStore.isSelected(song.spotify_track_id)"
          :class="{ 'selected': songSelectionStore.isSelected(song.spotify_track_id) }" @open-lyrics="openLyrics"
          @toggle-selection="songSelectionStore.toggleSongSelection(song)" />
      </transition-group>
    </main>

    <!-- <aside id="sidebar" class="sidebar" :class="{ 'expanded': isExpanded }" :style="{ width: sidebarWidth + 'px' }"> -->
    <aside id="sidebar" class="sidebar" :class="{ 'expanded': isExpanded }">
      <div class="sidebar-content-container">
        <!-- <div class="sidebar-handle" @mousedown="startResize"></div> -->
        <button @click="toggleSidebar" class="sidebar-toggle-button">
          {{ !isExpanded ? '<<' : '>>' }} </button>
            <SpotifyAuth v-if="playlistId" :playlistId="playlistId" />
            <SidebarSongs />
            <SpotifyPlayer :playlistId="playlistId" />
      </div>
    </aside>
    <LyricsModal :song="selectedSong" />
    <button id="toTop" class="btn btn-primary" @click="scrollToTop()">
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
        style="fill: rgba(255,255, 255, 1);">
        <g transform="scale(2) translate(-6,-5)">
          <path d="m6.293 13.293 1.414 1.414L12 10.414l4.293 4.293 1.414-1.414L12 7.586z"></path>
        </g>
      </svg>
    </button>
     <ToolTip />
  </div>
</template>

<script>
import { ref, onMounted, onUnmounted, computed, watch } from 'vue';

import SpotifyAuth from './SpotifyAuth.vue';
import CategorySelector from './CategorySelector.vue';
import SearchBar from './SearchBar.vue';
import SongItem from './SongItem.vue';
import AboutInfo from './About.vue';
import LyricsModal from './LyricsModal.vue';
import { useSongSelectionStore } from '../useSongSelection.js';
import SidebarSongs from './SidebarSongs.vue';
import SpotifyPlayer from './SpotifyPlayer.vue';
import ToolTip from './ToolTip.vue';

const LOCALSTORAGE_KEY = 'top100_settings'


export default {
  name: 'SongListNew',
  components: {
    SpotifyAuth,
    CategorySelector,
    SearchBar,
    SongItem,
    LyricsModal,
    SidebarSongs,
    AboutInfo,
    SpotifyPlayer,
    ToolTip
  },
  setup() {

    const songSelectionStore = useSongSelectionStore()
    const songs = ref([]);
    const loading = ref(true);
    const showModal = ref(false);useSongSelectionStore
    const selectedSong = ref({});
    const searchKeyword = ref('');
    const categories = ref([]);
    const isScrolled = ref(false);
    // const categoryButtonsHeight = ref(0);
    const playlistId = ref('');
    const currentCatInfo = ref({})

    // const sidebarWidth = ref(300); // Initial width
    // const isResizing = ref(false);


    const settings = ref(JSON.parse(localStorage.getItem(LOCALSTORAGE_KEY)) || {
      sidebarExpanded: false,
      // selectedCategory: '',
      // Add more settings as needed
    });

    const isExpanded = computed({
      get: () => settings.value.sidebarExpanded,
      set: (value) => settings.value.sidebarExpanded = value
    });

    const toggleSidebar = () => {
      isExpanded.value = !isExpanded.value;
      document.querySelector('#app').classList.toggle('expanded', isExpanded.value);
    };



    watch(settings, (newSettings) => {
      localStorage.setItem(LOCALSTORAGE_KEY, JSON.stringify(newSettings));
    }, { deep: true });

    // watch(() => songSelectionStore.selectedSongs, (newValue) => {
    // }, { deep: true });

    const toggleParentClass = () => {
      event.target.parentElement.parentElement.classList.toggle('expanded');
    };

    onMounted(async () => {
      window.addEventListener('resize', updateCategoryButtonsHeight);
      window.addEventListener('scroll', handleScroll);

      document.querySelector('#app').classList.toggle('expanded', isExpanded.value);
      // open sidebar by default if window is wide enough
      if (window.innerWidth > 1000) {
        setTimeout(() => {
          toggleSidebar();
        }, 200);
      }
      // fetch categories
      categories.value = await fetchFileList();
      // add categories to store 
      songSelectionStore.setCategories(categories.value);
      updateCategoryButtonsHeight();
      const randomCat = categories.value[Math.floor(Math.random() * categories.value.length)];
      fetchCategory(randomCat.key)
    });

    const handleScroll = () => {
      updateCategoryButtonsHeight();
      isScrolled.value = window.scrollY > 100;
      // add scrolled as a css custom variable
      document.documentElement.style.setProperty('--scrolled', window.scrollY + 'px');
    };

    const updateCategoryButtonsHeight = () => {
      const container = document.querySelector('#header');
      if (container) {
        const rect = container.getBoundingClientRect();
        // alert(rect.height);
        // categoryButtonsHeight.value = rect.height;
        // set a css custom variable
        document.documentElement.style.setProperty('--header-height', rect.height + 'px');
      }
    };


    onUnmounted(() => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', updateCategoryButtonsHeight);
    });


    const filteredSongs = computed(() => {
      if (!searchKeyword.value) return songs.value;
      if (searchKeyword.value.length < 3) return songs.value;
      return songs.value.filter(song =>
        song.genius_lyrics.toLowerCase().includes(searchKeyword.value.toLowerCase()) ||
        song.artist_name.toLowerCase().includes(searchKeyword.value.toLowerCase()) ||
        song.song_name.toLowerCase().includes(searchKeyword.value.toLowerCase())
        // song.album_name.toLowerCase().includes(searchKeyword.value.toLowerCase()) 
      );
    });

    const resetSearch = () => {
      searchKeyword.value = '';
    };

    const fetchCategory = async (category) => {
      loading.value = true;
      // console.log('fetching category', category)
      songSelectionStore.setSelectedCategory(category)
      try {
        const response = await fetch(`https://100-songs-select-file.lars-26f.workers.dev/?file=${category}`);
        const data = await response.json();
        songs.value = data.items;
        playlistId.value = data.id;
        updateCategoryButtonsHeight();
        // how to log a proxy object? -> JSON.stringify(data)
        currentCatInfo.value = categories.value.filter(cat => cat.key === category)[0]
        // scroll to top
        scrollToTop();

      } catch (error) {
        console.error('Error fetching category:', error);
        songSelectionStore.setSelectedCategory('')
      } finally {
        loading.value = false;
      }
    };

    const fetchFileList = async () => {
      try {
        const response = await fetch('https://100-songs-list-files.lars-26f.workers.dev/');
        let files = await response.json()
        let info = {};
        try {
          // const info = files['info_toc.jsonc']; // get info lookup toc
          const infoContent = await fetch(`https://100-songs-select-file.lars-26f.workers.dev/?file=info_toc`);
          info = await infoContent.json();
        } catch (error) {
          console.error('Error fetching info:', error);
          throw error;
        }
        // unset info from files;
        files = files.filter(item => !item.includes('info_toc.json'));
        files = files.map(name => {
          const key = name.split('.')[0]
          const item = info[key];
          // kind of type def categories
          return {
            key: key,
            label: item['label'],
            order: item['order'],
            description: item['description'],
            spotify_cat_id: item['spotify_cat_id'],
          }
        });
        return files.sort((a, b) => (a.order > b.order) ? 1 : -1);
      } catch (error) {
        console.error('Error fetching categories:', error);
      } finally {
        loading.value = false;
      }
    };

    const openLyrics = (song) => {
      selectedSong.value = song;
      showModal.value = true;
    };

    const formattedLyrics = computed(() => {
      if (!selectedSong.value.lyrics) return '';
      const lines = selectedSong.value.lyrics.split('\n');
      const firstLine = lines[0].split(' ');
      const contributors = firstLine[0];
      const songTitle = firstLine.slice(2, -1).join(' ');
      const songTitleRegex = /(\d+)Embed/g;
      lines[lines.length - 1].replace(songTitleRegex, '');
      let output = `<em>${contributors} Contributor(s)</em><br><br>`;
      output += `<strong>${songTitle}</strong><br><br>`;
      output += lines.slice(2).map(line => `${line}<br>`).join('');
      return output;
    });



    const scrollToTop = () => {
      // Scroll to the top of the page
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    }



    return {
      songs,
      loading,
      showModal,
      selectedSong,
      categories,
      fetchCategory,
      openLyrics,
      formattedLyrics,
      searchKeyword,
      filteredSongs,
      isScrolled,
      // categoryButtonsHeight,
      resetSearch,
      toggleParentClass,
      playlistId,
      songSelectionStore,
      isExpanded,
      toggleSidebar,
      currentCatInfo,
      scrollToTop
      // sidebarWidth
    };
  }
};
</script>

<style scoped>
/* ... (styles remain the same) */
</style>