<template>
  <div id="lyrics">
    <div class="modal-content" v-if="song && song.song_name">
      <h2>{{ infoContent ? 'Info' : song.song_name + ' from ' + song.artist_name }}</h2>
      <div v-if="infoContent">
        <div v-html="infoContent"></div>
      </div>
      <div v-else>
        <img :src="song.genius_image_url" alt="Album Art" class="album-art">
        <p><a :href="song.genius_url" target="_blank">Credits (see @Genius)</a></p>
        <div v-html="formattedLyrics"></div>
      </div>
    </div>
  </div>
</template>


<script>
import { computed } from 'vue';

export default {
  name: 'LyricsModal',
  props: ['song', 'infoContent'],
  emits: ['close'],
  setup(props) {

    

    const formattedLyrics = computed(() => {
      if (!props.song.genius_lyrics) return '';
      const lines = props.song.genius_lyrics.split('\n');
      const firstLine = lines[0].split(' ');
      const contributors = firstLine[0];
      const songTitle = firstLine.slice(2, -1).join(' ');
      const songTitleRegex = /(\d+)Embed/g;
      lines[lines.length - 1].replace(songTitleRegex, '');
      let output = `<em>${contributors} Contributor(s)</em><br><br>`;
      output += `<strong>${songTitle}</strong><br><br>`;
      output += lines.slice(2).map(line => `${line}<br>`).join('');
      return output;
    });

    return { formattedLyrics };
  }
}
</script>


<style scoped>
#lyrics img {
  max-width: 100%;
}
</style>