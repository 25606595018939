<template>

    <div class="sidebar-container sidebar-container-connect">
        <h2>Spotify Connect</h2>
        <button v-if="!isAuthenticated" @click="initiateAuth" class="auth-button">
            Connect to Spotify
        </button>
        <div v-else class="auth-status">
            <p>Connected to Spotify</p>
            <button @click="logout" class="logout-button">Logout</button>
        </div>
        <div v-if="isAuthenticated" class="sidebar-container-connect-playlists">
            <h2>Your Playlists</h2>
            <div class="playlists-select-container">
                <button @click="fetchPlaylists" class="fetch-playlists-button">
                    Update
                </button>
                <select v-if="playlists.length" @change="onSelectPlaylist">
                    <option value="">Select Playlist</option>
                    <option v-for="playlist in playlists" :key="playlist.id" :value="playlist.id">
                        {{ playlist.name }}
                    </option>
                </select>
            </div>
            <em>Creation of playlist on <a href="https://spotify.com" target="_blank">spotify</a> only! Click update
                afterwards!</em>
        </div>



    </div>
</template>

<script>
import { ref, onMounted, computed, watch } from 'vue';
import { useSongSelectionStore } from '../useSongSelection'
export default {
    name: 'SpotifyAuth',
    props: {
        playlistId: {
            type: String,
            default: ''
        }
    },
    setup() {
        const isAuthenticated = ref(false);
        const accessToken = ref('');
        const playlists = ref([]);
        const {
            setIsSpotifyConnected,
            setSelectedSpotifyPlaylist,
            setSelectedSpotifyPlaylistName,
            setCurrentUsersPlaylists
        } = useSongSelectionStore()
        onMounted(() => {
            const storedToken = localStorage.getItem('spotify_access_token');
            if (storedToken) {
                if (!isTokenExpired()) {
                    accessToken.value = storedToken;
                    isAuthenticated.value = true;
                    setIsSpotifyConnected(true);
                    fetchPlaylists();
                } else {
                    logout();
                }
            } else {
                handleAuthCallback();
            }
            // Check token status every minute
            setInterval(checkTokenStatus, 60000);
        });
        const onSelectPlaylist = (event) => {
            if (!event.target.value) {
                setSelectedSpotifyPlaylist('')
                setSelectedSpotifyPlaylistName('')
                return;
            }
            const playlistId = event.target.value;
            const playlistName = playlists.value.find(playlist => playlist.id === playlistId).name;
            // console.log('onSelectPlaylist', playlistId, playlistName);
            setSelectedSpotifyPlaylist(playlistId)
            setSelectedSpotifyPlaylistName(playlistName)
        }
        const fetchPlaylists = async () => {
            checkTokenStatus();
            if (!isAuthenticated.value) return;
            try {
                const response = await fetch('https://api.spotify.com/v1/me/playlists', {
                    headers: {
                        'Authorization': `Bearer ${accessToken.value}`
                    }
                });
                const data = await response.json();
                playlists.value = data.items;
                // console.log('Fetched playlists:', data);
                // lets filter ids from data.items[i].id
                // const playlist_ids = data.items.map(playlist => playlist.id)
                const playlist_id_name = data.items.map(playlist => [playlist.id, playlist.name])
                // console.log('playlist_ids', playlist_ids,playlist_id_name)
                setCurrentUsersPlaylists(playlist_id_name)
            } catch (error) {
                console.error('Error fetching playlists:', error);
            }
        };

        const initiateAuth = () => {
            const clientId = process.env.VUE_APP_SPOTIFY_CLIENT_ID;
            const redirectUri = process.env.VUE_APP_APP_URL;
            const scopes = 'playlist-modify-public playlist-modify-private';
            const authUrl = `https://accounts.spotify.com/authorize?client_id=${clientId}&response_type=code&redirect_uri=${encodeURIComponent(redirectUri)}&scope=${encodeURIComponent(scopes)}`;
            window.location.href = authUrl;
        };

        const isTokenExpired = () => {
            const tokenTimestamp = localStorage.getItem('spotify_token_timestamp');
            if (!tokenTimestamp) return true;
            const currentTime = new Date().getTime();
            const tokenAge = currentTime - parseInt(tokenTimestamp);
            return tokenAge > 3600000; // 1 hour in milliseconds
        };

        const checkTokenStatus = () => {
            if (isTokenExpired()) {
                logout();
                // Optionally, show a message to the user that they need to re-login
            }
        };

        // async function sendOptionsRequest(url) {
        //     const response = await fetch(url, {
        //         method: 'OPTIONS',
        //         headers: {
        //             'Access-Control-Request-Method': 'POST',
        //             'Access-Control-Request-Headers': 'Content-Type'
        //         }
        //     });
        //     return response.ok;
        // }

        const handleAuthCallback = async () => {
            
            const urlParams = new URLSearchParams(window.location.search);
            const code = urlParams.get('code');
            if (code) {

                // const url = 'https://100songs-get-spotify-access-token.lars-26f.workers.dev/';
                // const optionsOk = await sendOptionsRequest(url);
                try {
                    // the browser is firing this request, so origin is empty
                    // lets add a no-cors header to the fetch request
                    const response = await fetch('https://100songs-get-spotify-access-token.lars-26f.workers.dev/', {
                        method: 'POST',
                        headers: { 
                            'Content-Type': 'application/json',
                            'Origin': window.location.origin,
                        },
                        body: JSON.stringify({ code })
                    });
                    const data = await response.json();
                    // console.log(data)
                    if (data.access_token) {
                        accessToken.value = data.access_token;
                        isAuthenticated.value = true;
                        setIsSpotifyConnected(true);
                        localStorage.setItem('spotify_access_token', accessToken.value);
                        localStorage.setItem('spotify_token_timestamp', new Date().getTime().toString());
                        fetchPlaylists();
                        // remove code param from url
                        window.history.pushState({}, '', process.env.VUE_APP_APP_URL);
                    }
                } catch (error) {
                    console.error('Error exchanging code for token:', error);
                }
            }
        };

        const logout = () => {
            accessToken.value = '';
            isAuthenticated.value = false;
            setIsSpotifyConnected(false);
            localStorage.removeItem('spotify_access_token');
        };

        const addSongToPlaylistOFF = async (spotifySongId, spotifyPlaylistId) => {
            console.log('addSongToPlaylist', spotifySongId, spotifyPlaylistId);
            // const songId = '3qT4bUD1MaWpGrTwcvguhb';
            // const playLid = ''
            // const endpoint = `https://api.spotify.com/v1/playlists/${playLid}/tracks`;

            // try {
            //     const response = await fetch(endpoint, {
            //         method: 'POST',
            //         headers: {
            //             'Authorization': `Bearer ${accessToken.value}`,
            //             'Content-Type': 'application/json'
            //         },
            //         body: JSON.stringify({
            //             uris: [`spotify:track:${songId}`]
            //         })
            //     });

            //     if (response.ok) {
            //         console.log('Song added to playlist successfully');
            //     } else {
            //         console.error('Failed to add song to playlist');
            //     }
            // } catch (error) {
            //     console.error('Error adding song to playlist:', error);
            // }
        };

        onMounted(() => {
            const storedToken = localStorage.getItem('spotify_access_token');

            if (storedToken) {
                accessToken.value = storedToken;
                isAuthenticated.value = true;
                setIsSpotifyConnected(true);
            } else {
                handleAuthCallback();
            }
        });

        // does not listening to changes in selectedSongs


        return {
            isAuthenticated,
            initiateAuth,
            logout,
            onSelectPlaylist,
            playlists,
            fetchPlaylists,
        };
    }
};
</script>

<style scoped></style>
